import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Willingen has some nice, but a little bit hidden singletracks. If you want
to go directly to the trails on Trailguide,
`}<a parentName="strong" {...{
          "href": "https://trailguide.net/?pos=12,51.3142,8.5681"
        }}>{`you can find them here.`}</a></strong></p>
    <br />
    <p>{`Willingen is the center of mountain biking in the Sauerland, a German
Mittelgebirge. The Ischgl of northern Germany has a well known bikepark and
is also famous for the biggest Bikefestival in the country.`}</p>
    <br />
    <p>{`Outside the bikepark, on first sight, it looks like there are only long tours
on gravel roads. But surprisingly we also found nice singletracks:`}</p>
    <TrailguidePlugin content="lat=51.3142&lng=8.5681&coverage=10" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=12,51.3142,8.5681"
        }}>{`web-app`}</a></p>
    </p>
    <p>{`Very close to the town, some short but cool trails hide on the Orenberg.
With a bit of easy pedaling - here comes the advantage of the Mittelgebirge:
it's not steep - you can connect some nice descents to a satisfying
singletrack tour in a beautiful nature, and can avoid the brake bumps of
the bikepark. The villages around Willingen offering the best trails are
Niedersfeld, Bruchhausen, and Olsberg.`}</p>
    <br />
    <p>{`A bit further north is Brilon.
With the Trailground Brilon, they picked up the trend of enduro/ trail biking.
A nice loop connects 6 descents and also some uphills are on singltrack.
The trails are perfect for beginners, but become challenging when riding
faster so advanced bikers can have a lot of fun too, or work on their
cornering skills.`}</p>
    <br />
    <p>{`The whole area doesn't seem to be popular for enduro/ trail biking and you
mostly see bikers in the park or a mix of old scool XC and gravel biker.
So beside some hikers, you will be alone on the trails.`}</p>
    <br />
    <p>{`First of all Willingen is known for its bikepark.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2927"
      }}>{`Find the trail here.`}</a></p>
    <Image src="destinations/willingenbrilon_9.jpg" mdxType="Image" />
    <br />
    <p>{`But around Willingen and in the next valleys, a lot of trails hide in the woods.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2918"
      }}>{`Find the trail here.`}</a></p>
    <Image src="destinations/willingenbrilon_6.jpg" mdxType="Image" />
    <br />
    <p>{`It's not a dense network of singletracks, but you can ride nice
loops to connect a few descents.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2923"
      }}>{`Find the trail here.`}</a></p>
    <Image src="destinations/willingenbrilon_7.jpg" mdxType="Image" />
    <br />
    <p>{`Directly next to Willingen is the Orenberg.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2930"
      }}>{`Find the trail here.`}</a></p>
    <Image src="destinations/willingenbrilon_10.jpg" mdxType="Image" />
    <br />
    <p>{`For some tours you can use the gondola to make the uphill easier,
or ride in the bikepark.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2918"
      }}>{`Find the trail here.`}</a></p>
    <Image src="destinations/willingenbrilon_14.jpg" mdxType="Image" />
    <br />
    <p>{`Après Ski without the ski part.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=12,51.3142,8.5681"
      }}>{`Find the trail here.`}</a></p>
    <Image src="destinations/willingenbrilon_12.jpg" mdxType="Image" />
    <br />
    <p>{`North of Willingen is Brilon. With the Trailground Brilon they created a
nice loop that connects 6 descents.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2906"
      }}>{`Find the trail here.`}</a></p>
    <Image src="destinations/willingenbrilon_2.jpg" mdxType="Image" />
    <br />
    <p>{`Can you see the flow?
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2906"
      }}>{`Find the trail here.`}</a></p>
    <Image src="destinations/willingenbrilon_4.jpg" mdxType="Image" />
    <br />
    <p>{`Olsberg is between Brilon and Willingen and also offers some nice trails.
Here with a view into the Sauerland.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2935"
      }}>{`Find the trail here.`}</a></p>
    <Image src="destinations/willingenbrilon_1.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      